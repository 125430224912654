/* BASIC VARIABLES SETUP*/
/* FONTS */
$primary-font: 'Roboto', sans-serif
$font-size: 16px
$font-weight: 400
$letter-spacing: 1px
$line-height: 24px
$main-spacing: 0.3rem

/* VIEWS */
$base-padding: 12px
$max-width: 40rem
$small-width: 85vw
$full-width: 1170px

/* ANIMATION */
$main-transition: all 0.2s linear

/* SHADOWS */
$light-shadow: 2px 5px 3px 0px rgba(0, 0, 0, 0.5)
$dark-shadow: 4px 10px 5px 0px rgba(0, 0, 0, 0.5)
$text-shadow: 1px 1px 1px rgba(0,0,0,0.8)
