@import "node_modules/bootstrap/scss/functions"
@import 'assets/sass/theme'
@import "assets/sass/settings"
@import '~quill/dist/quill.bubble.css'
@import '~quill/dist/quill.snow.css'

// Variable overrides second
$primary: #900
$enable-shadows: true
$prefix: "mo-"

// Required Bootstrap imports
@import "node_modules/bootstrap/scss/variables"
@import "node_modules/bootstrap/scss/maps"
@import "node_modules/bootstrap/scss/mixins"
@import "node_modules/bootstrap/scss/root"

// Optional components
@import "node_modules/bootstrap/scss/utilities"
@import "node_modules/bootstrap/scss/reboot"
@import "node_modules/bootstrap/scss/containers"
@import "node_modules/bootstrap/scss/grid"
@import "node_modules/bootstrap/scss/utilities/api"

*,*::before,*::after
  box-sizing: border-box
  scrollbar-color: map-get($grey, 500, 100)
  &::-webkit-scrollbar
    width: 5px
    height: 5px
  &::-webkit-scrollbar-track
    background-color: map-get($grey, 100)
  &::-webkit-scrollbar-thumb
    background-color: map-get($grey, 500)
    border-radius: 5px

html
  font-size: $font-size
  height: 100%
  width: 100%

  @media all and (max-height: 1080px)
    font-size: 14px

body
  height: 100%
  width: 100%
  margin: 0
  padding: 0
  background-color: map-get($grey, 50)
  color: map-get($grey, 1100)
  font-family: $primary-font
  position: relative
  overflow-x: hidden

.fullHeight
  height: 100%
  display: block

//Scroll
::-webkit-scrollbar
  width: 7px

::-webkit-scrollbar-track
  background: #f1f1f1

::-webkit-scrollbar-thumb
  background: map-get($md-palette,400)

::-webkit-scrollbar-thumb:hover
  background: map-get($md-palette,500)

*
  scrollbar-width: thin
  scrollbar-color: #f1f1f1 map-get($md-palette,500)

*
  -ms-overflow-style: none

//checkboxes
.mat-mdc-checkbox .mdc-checkbox .mdc-checkbox__native-control:enabled:checked~.mdc-checkbox__background
  border-color: map-get($md-palette, 500) !important
  background-color: map-get($md-palette, 500) !important

//toggles
.mat-mdc-tab-group.mat-mdc-tab-group-stretch-tabs>.mat-mdc-tab-header .mat-mdc-tab
  font-size: 1.7rem
.mat-mdc-tab:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label
  color: map-get($md-palette, 500)
.mat-mdc-tab:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline
  border-color: map-get($md-palette, 500)

//textarea
.textAreaInput > .mat-mdc-form-field-subscript-wrapper
  display: none!important

//mat-radio-buttons
.mat-mdc-radio-button .mdc-radio .mdc-radio__native-control:enabled:checked+.mdc-radio__background .mdc-radio__outer-circle
  border-color: map-get($md-palette, 500)!important
.mat-mdc-radio-button .mdc-radio .mdc-radio__native-control:enabled+.mdc-radio__background .mdc-radio__inner-circle
  border-color: map-get($md-palette, 500)!important

//mat-toggle
.mat-mdc-slide-toggle .mdc-switch:enabled .mdc-switch__track::after
  background-color: #FCA304!important

.mat-mdc-slide-toggle.mat-primary
  --mdc-switch-selected-focus-state-layer-color: #FCA304
  --mdc-switch-selected-handle-color: #FCA304
  --mdc-switch-selected-hover-state-layer-color: #FCA304
  --mdc-switch-selected-pressed-state-layer-color: #FCA304
  --mdc-switch-selected-focus-handle-color: #FCA304
  --mdc-switch-selected-hover-handle-color: #FCA304
  --mdc-switch-selected-pressed-handle-color: #FCA304
  --mdc-switch-selected-focus-track-color: #FCA304
  --mdc-switch-selected-hover-track-color: #FCA304
  --mdc-switch-selected-pressed-track-color: #FCA304
  --mdc-switch-selected-track-color: #FCA304

.mat-mdc-slide-toggle .mdc-switch
  &:hover
    background-color: transparent!important

//labels
.mdc-text-field--focused:not(.mdc-text-field--disabled) .mdc-floating-label
  color: #BE7A02

//inputs
.mdc-text-field--filled:not(.mdc-text-field--disabled)
  background-color: transparent
  border: 1px solid map-get($grey, 400)
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button
  -webkit-appearance: none
  margin: 0
.mdc-text-field--filled .mdc-line-ripple::after
  border-bottom-color: map-get($md-palette, 400)
//dialog
.mdc-dialog__container
  min-width: 30rem!important
  max-width: 45rem!important
.link
  text-decoration: none
  color: blue
  &:hover
    text-decoration: underline
    color: blue
